import { commonEnv } from './environment.common';

export const environment = {
  ...commonEnv,
  environmentLabel: null,
  production: true,
  version: '1.10.57',
  commitHash: '9403313792eebf17030045c010f2730704589cb0',
  sentry: '',
  boomerangApiUrl: 'http://localhost:5001',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'boomerang',
    bearerExcludedUrls: ['http://localhost:4201'],
    scope: 'openid email profile offline_access',
    twoFaTimeLimit: 120,
    logoutUrl: 'http://localhost:4201',
  },
};
